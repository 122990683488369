var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "shop"
  }, [_c('Nav'), _c('Header'), _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "screen"
  }, [_c('div', {
    staticClass: "inp"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.show = true;
      }
    }
  }, [_vm._v("新增")]), _c('el-button', [_c('download-excel', {
    staticClass: "export-btn",
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "data": _vm.shoplist,
      "fields": _vm.jsonFields,
      "type": "xls",
      "header": "店铺列表",
      "name": "店铺列表.xls"
    }
  }, [_vm._v(" 导出 ")])], 1)], 1)]), _c('div', {
    staticClass: "tab"
  }, [_c('el-table', {
    staticClass: "table",
    staticStyle: {
      "width": "95%",
      "margin": "auto"
    },
    attrs: {
      "header-cell-style": {
        background: '#F4F6FA'
      },
      "data": _vm.shoplist.slice((_vm.currentPage - 1) * _vm.pagesize, _vm.currentPage * _vm.pagesize)
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "storeName",
      "label": "门店名称"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "code",
      "width": "200",
      "label": "核销码"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "address",
      "label": "地址"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "createTime",
      "label": "创建时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "type": "index",
      "align": "center",
      "width": "160",
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.set(scope.row.id);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c('el-popconfirm', {
          attrs: {
            "confirm-button-text": "好的",
            "cancel-button-text": "不用了",
            "icon": "el-icon-info",
            "icon-color": "red",
            "title": "确定删除吗？"
          },
          on: {
            "confirm": function ($event) {
              return _vm.del(scope.row.id);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "type": "danger",
            "size": "mini"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pag"
  }, [_c('el-pagination', {
    attrs: {
      "layout": "  prev, pager, next, sizes, jumper",
      "page-sizes": [10],
      "page-size": _vm.pagesize,
      "total": _vm.shoplist.length,
      "current-page": _vm.currentPage
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)]), _c('el-dialog', {
    attrs: {
      "lock-scroll": false,
      "title": "编辑门店",
      "visible": _vm.save
    },
    on: {
      "update:visible": function ($event) {
        _vm.save = $event;
      }
    }
  }, [_c('div', {
    staticClass: "from"
  }, [_c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.update.storeName,
      callback: function ($$v) {
        _vm.$set(_vm.update, "storeName", $$v);
      },
      expression: "update.storeName"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("门店名称：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.update.address,
      callback: function ($$v) {
        _vm.$set(_vm.update, "address", $$v);
      },
      expression: "update.address"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("地址：")])], 2)], 1), _c('div', {
    staticClass: "from-inp",
    staticStyle: {
      "display": "none"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "hidden"
    },
    model: {
      value: _vm.update.storeName,
      callback: function ($$v) {
        _vm.$set(_vm.update, "storeName", $$v);
      },
      expression: "update.storeName"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("核销员：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text",
      "maxlength": "6"
    },
    on: {
      "input": function ($event) {
        _vm.update.code = _vm.update.code.replace(/[^\d]/g, '');
      }
    },
    model: {
      value: _vm.update.code,
      callback: function ($$v) {
        _vm.$set(_vm.update, "code", $$v);
      },
      expression: "update.code"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("核销码：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.con_set
    }
  }, [_vm._v("确认")]), _c('el-button', {
    attrs: {
      "type": "warning",
      "plain": ""
    },
    on: {
      "click": _vm.edit_show
    }
  }, [_vm._v("取消")])], 1)])]), _c('el-dialog', {
    attrs: {
      "lock-scroll": false,
      "title": "新增门店",
      "visible": _vm.show
    },
    on: {
      "update:visible": function ($event) {
        _vm.show = $event;
      }
    }
  }, [_c('div', {
    staticClass: "from"
  }, [_c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.add.storeName,
      callback: function ($$v) {
        _vm.$set(_vm.add, "storeName", $$v);
      },
      expression: "add.storeName"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("门店名称：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.add.address,
      callback: function ($$v) {
        _vm.$set(_vm.add, "address", $$v);
      },
      expression: "add.address"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("地址：")])], 2)], 1), _c('div', {
    staticClass: "from-inp",
    staticStyle: {
      "display": "none"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "hidden"
    },
    model: {
      value: _vm.add.storeName,
      callback: function ($$v) {
        _vm.$set(_vm.add, "storeName", $$v);
      },
      expression: "add.storeName"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("核销员：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text",
      "maxlength": "6"
    },
    on: {
      "input": function ($event) {
        _vm.add.code = _vm.add.code.replace(/[^\d]/g, '');
      }
    },
    model: {
      value: _vm.add.code,
      callback: function ($$v) {
        _vm.$set(_vm.add, "code", $$v);
      },
      expression: "add.code"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("核销码：")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_vm._v("确认")]), _c('el-button', {
    attrs: {
      "type": "warning",
      "plain": ""
    },
    on: {
      "click": _vm.add_show
    }
  }, [_vm._v("取消")])], 1)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_vm._v(" 店铺管理 ")])]);
}];
export { render, staticRenderFns };