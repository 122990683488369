import Header from '../components/Header';
import Nav from '../components/nav';
export default {
  name: 'Home',
  components: {
    Header,
    Nav
  },
  data() {
    return {
      name: '',
      jsonFields: {
        //表格头
        '门店名称': 'storeName',
        '核销码': 'code',
        '地址': 'address',
        '创建时间': 'createTime'
      },
      shoplist: [],
      show: false,
      //新增显示判断
      add: {
        address: '',
        //地址
        code: '',
        //核销码
        storeName: '' //门店名称
      },
      update: {
        id: '',
        address: '',
        code: '',
        storeName: ''
      },
      currentPage: 1,
      //展示第几页
      pagesize: 10,
      //每页的数据条数
      save: false
    };
  },
  created() {
    let t = this;
    t.init();
  },
  methods: {
    init() {
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios({
        url: 'store/getStoreList',
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          let arr = res.data.data;
          t.shoplist = arr;
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    insert() {
      let t = this;
      if (!t.add.address || !t.add.code || !t.add.storeName) {
        t.$message.error('数据不能为空');
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        t.$axios.post('store/addStore', t.add, {
          headers: {
            'auth-token': t.$cookies.get('token')
          }
        }).then(res => {
          if (res.data.code === 200) {
            loading.close();
            t.$message({
              message: '添加成功',
              type: 'success'
            });
            t.init();
            t.add = {
              address: '',
              //地址
              code: '',
              //核销码
              storeName: '' //门店名称
            };
          } else {
            loading.close();
            t.$message.error(res.data.msg || '请刷新重试');
          }
        }).catch(err => {
          loading.close();
          t.$message.error(err.message || '内部服务器错误请联系管理员');
        });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    //点击改变页数和数据
    del(ind) {
      let t = this;
      t.$axios.delete('store/delete', {
        params: {
          storeId: ind
        }
      }, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          t.$message({
            message: '删除成功',
            type: 'success'
          });
          t.init();
        } else {
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    set(id) {
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios({
        headers: {
          'auth-token': t.$cookies.get('token')
        },
        params: {
          storeId: id
        },
        url: 'store/getStore'
      }).then(res => {
        if (res.data.code == 200) {
          t.update.id = res.data.data.id;
          t.update.address = res.data.data.address;
          t.update.code = res.data.data.code;
          t.update.storeName = res.data.data.storeName;
          t.save = true;
          loading.close();
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    //确认修改
    con_set() {
      let t = this;
      if (!t.update.address || !t.update.code || !t.update.storeName) {
        t.$message.error('数值不能为空');
      } else {
        t.$axios({
          headers: {
            'auth-token': t.$cookies.get('token')
          },
          method: 'put',
          url: 'store/updateStore',
          data: t.update
        }).then(res => {
          if (res.data.code == 200) {
            t.$message({
              message: '编辑',
              type: 'success'
            });
            t.init();
            t.save = false;
          } else {
            t.$message.error(res.data.msg || '内部服务器错误请联系管理员');
          }
        }).catch(err => {
          console.log(err);
          t.$message.error(err.message || '内部服务器错误请联系管理员');
        });
      }
    },
    edit_show() {
      let t = this;
      t.save = false;
    },
    add_show() {
      let t = this;
      t.show = false;
    }
  }
};