export default {
  data() {
    return {
      menu: [{
        id: 1,
        path: '/',
        name: '用户管理',
        icon: 'el-icon-user-solid'
      }, {
        id: 2,
        path: '/Shop',
        name: '店铺管理',
        icon: 'el-icon-s-shop'
      }, {
        id: 3,
        path: '/Draw',
        name: '抽奖管理',
        icon: 'el-icon-s-goods'
      }, {
        id: 4,
        path: '/Config',
        name: '配置管理',
        icon: 'el-icon-s-tools'
      }, {
        id: 5,
        path: '/Prize',
        name: '兑奖名单',
        icon: 'el-icon-s-grid'
      }],
      nav: this.$store.state.nav
    };
  },
  methods: {}
};