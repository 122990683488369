var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home"
  }, [_c('Nav'), _c('Header'), _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "screen"
  }, [_c('div', {
    staticClass: "inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("姓名：")])], 2)], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "number"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("手机号：")])], 2)], 1), _c('div', {
    staticClass: "inp"
  }, [_vm.excel_status == 1 ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("查询")]) : _vm._e(), _c('el-button', [_c('download-excel', {
    staticClass: "export-btn",
    attrs: {
      "data": _vm.derive_list,
      "fields": _vm.jsonFields,
      "type": "xls",
      "header": "用户列表",
      "name": "用户列表.xls"
    }
  }, [_vm._v(" 导出 ")])], 1)], 1)]), _c('div', {
    staticClass: "tab"
  }, [_c('el-table', {
    staticClass: "table",
    staticStyle: {
      "width": "95%",
      "margin": "auto"
    },
    attrs: {
      "header-cell-style": {
        background: '#F4F6FA'
      },
      "data": _vm.userlist
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "id",
      "label": "序号"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "name",
      "label": "姓名"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "phoneNo",
      "label": "手机号"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "createTime",
      "width": "160",
      "label": "注册时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userInfoVO.isLookFen",
      "label": "是否完成颜值测试"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userInfoVO.isIntelligenceFen",
      "label": "是否完成智力测试"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userInfoVO.receiveCard",
      "label": "是否生成基金卡"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userInfoVO.totalFen",
      "label": "剩余乐币"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userInfoVO.address",
      "label": "收货地址"
    }
  }), _c('el-table-column', {
    attrs: {
      "type": "index",
      "width": "200",
      "align": "center",
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.show(scope.row.id);
            }
          }
        }, [_vm._v(" 查看奖品 ")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "pag"
  }, [_c('el-pagination', {
    attrs: {
      "layout": "  prev, pager, next, sizes, jumper",
      "page-sizes": [10],
      "page-size": _vm.pagesize,
      "total": _vm.total,
      "current-page": _vm.currentPage
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1), _c('el-dialog', {
    attrs: {
      "lock-scroll": false,
      "title": "查看奖品",
      "visible": _vm.dialogTableVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogTableVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "dia-table"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.prize
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "property": "id",
      "label": "序号"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "property": "prizeName",
      "label": "领取状态"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "property": "receiveType",
      "label": "奖品名称"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "property": "receiveTime",
      "label": "中奖时间"
    }
  })], 1), _c('div', {
    staticClass: "dia-button"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" 确认 ")])], 1)], 1)])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_vm._v(" 用户管理 ")])]);
}];
export { render, staticRenderFns };