import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      user: this.$cookies.get('user')
    };
  },
  methods: {
    clear(command) {
      if (command == 'exit') {
        this.$cookies.remove("user");
        this.$cookies.remove("token");
        this.$router.push({
          path: '/Login'
        });
        this.$message({
          message: '退出成功',
          type: 'success'
        });
      }
    }
  }
};