import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    nav: '/'
  },
  mutations: {
    menu(state, nav) {
      state.nav = nav;
    }
  },
  actions: {},
  modules: {}
});