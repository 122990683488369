import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import qs from 'qs'
import VueCookies from 'vue-cookies';
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
router.beforeEach((to, from, next) => {
	let token = VueCookies.get('token');
	if(token && to.path !== '/Login')
	{
		next()
	}else if(token && to.path === '/Login'){
		next('/');
	}else if(!token && to.path !== '/Login')
	{
		next('/Login')
	}else{
		next()
	}
	store.commit('menu',to.path);
})
axios.defaults.baseURL = 'https://apiadmin.wanjiale.test.spinus.vip/admin'
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
