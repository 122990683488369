import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Shop from '../views/shop.vue';
import Draw from '../views/draw.vue';
import Config from '../views/config.vue';
import Prize from '../views/prize.vue';
Vue.use(VueRouter);
const routes = [{
  path: '/',
  name: 'Home',
  component: Home
}, {
  path: '/Shop',
  name: 'Shop',
  component: Shop
}, {
  path: '/Draw',
  name: 'Draw',
  component: Draw
}, {
  path: '/Config',
  name: 'Config',
  component: Config
}, {
  path: '/Prize',
  name: 'Prize',
  component: Prize
}, {
  path: '/Login',
  name: 'Login',
  component: Login
}];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});
export default router;