var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nav"
  }, [_vm._m(0), _c('div', {
    staticClass: "user"
  }, [_c('el-dropdown', {
    on: {
      "command": _vm.clear
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" 用户：" + _vm._s(_vm.user)), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "command": "exit"
    }
  }, [_vm._v("退出登录")])], 1)], 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "el-icon-menu",
    staticStyle: {
      "font-size": "25px"
    }
  })]), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticStyle: {
      "font-size": "25px"
    }
  }, [_vm._v("万家乐后台管理系统")])])]);
}];
export { render, staticRenderFns };