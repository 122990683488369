import "core-js/modules/es.array.push.js";
// @ is an alias to /src
import Header from '../components/Header';
import Nav from '../components/nav';
export default {
  name: 'Home',
  components: {
    Header,
    Nav
  },
  data() {
    return {
      userlist: [],
      currentPage: 1,
      //展示第几页
      pagesize: 10,
      //每页的数据条数
      name: '',
      phone: '',
      dialogTableVisible: false,
      prize: [],
      total: 0,
      derive_list: [],
      //导出数据,
      jsonFields: {
        //表格头
        '序号': 'id',
        '姓名': 'name',
        '手机号': 'phoneNo',
        '注册时间': 'createTime',
        '是否完成颜值测试': 'userInfoVO.isLookFen',
        '是否完成智力测试': 'userInfoVO.isIntelligenceFen',
        '是否生成基金卡': 'userInfoVO.receiveCard',
        '剩余乐币': 'userInfoVO.totalFen',
        '收货地址': 'userInfoVO.address'
      },
      excel_status: 0,
      //导出全部数据是否加载完毕
      pages: 0 //总页数
    };
  },
  created() {
    let t = this;
    t.init();
  },
  methods: {
    educe() {
      let t = this;
      t.derive_list = [];
      t.excel_status = 0;
      t.$axios.post('user/getUserListExport', {
        name: t.name,
        phoneNo: t.phone
      }, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        let arr = res.data.data;
        for (let ind = 0; ind < arr.length; ind++) {
          if (arr[ind].userInfoVO.isLookFen) {
            arr[ind].userInfoVO.isLookFen = '是';
          } else {
            arr[ind].userInfoVO.isLookFen = '否';
          }
          if (arr[ind].userInfoVO.isIntelligenceFen) {
            arr[ind].userInfoVO.isIntelligenceFen = '是';
          } else {
            arr[ind].userInfoVO.isIntelligenceFen = '否';
          }
          if (arr[ind].userInfoVO.receiveCard == 1) {
            arr[ind].userInfoVO.receiveCard = '是';
          } else {
            arr[ind].userInfoVO.receiveCard = '否';
          }
          t.derive_list.push(arr[ind]);
        }
      });
      t.excel_status = 1;
    },
    init() {
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios.post('user/getUserList', {
        name: t.name,
        phoneNo: t.phone,
        current: t.currentPage
      }, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          let arr = res.data.data.records;
          t.pagesize = parseInt(res.data.data.size);
          t.total = parseInt(res.data.data.total);
          t.currentPage = parseInt(res.data.data.current);
          t.pages = parseInt(res.data.data.pages);
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].userInfoVO.isLookFen) {
              arr[i].userInfoVO.isLookFen = '是';
            } else {
              arr[i].userInfoVO.isLookFen = '否';
            }
            if (arr[i].userInfoVO.isIntelligenceFen) {
              arr[i].userInfoVO.isIntelligenceFen = '是';
            } else {
              arr[i].userInfoVO.isIntelligenceFen = '否';
            }
            if (arr[i].userInfoVO.receiveCard == 1) {
              arr[i].userInfoVO.receiveCard = '是';
            } else {
              arr[i].userInfoVO.receiveCard = '否';
            }
          }
          t.userlist = arr;
          t.educe();
          loading.close();
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    show(index) {
      let t = this;
      t.$axios({
        url: 'prize/getUserPrize',
        params: {
          userId: index
        },
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          let arr = res.data.data;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].receiveType == 0) {
              arr[i].receiveType = '未领取';
            } else if (arr[i].receiveType == 1) {
              arr[i].receiveType = '已领取';
            }
          }
          t.prize = res.data.data;
          t.dialogTableVisible = true;
        } else {
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    close() {
      this.dialogTableVisible = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    } //点击改变页数和数据
  }
};