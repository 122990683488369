import Header from '../components/Header';
import Nav from '../components/nav';
export default {
  name: 'Home',
  components: {
    Header,
    Nav
  },
  data() {
    return {
      i: '',
      prizelist: [],
      currentPage: 1,
      //展示第几页
      pagesize: 10,
      //每页的数据条数
      show: false,
      update: {
        id: '',
        amount: '',
        //奖品数量
        probability: '',
        //中奖概率
        drawDayCount: '' //每日中奖上限
      }
    };
  },
  created() {
    let t = this;
    t.init();
  },
  methods: {
    init() {
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios.post('prize/getPrizeAll', {}, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          loading.close();
          let arr = res.data.data;
          for (let i = 0; i < arr.length; i++) {
            arr[i]['residue'] = parseInt(arr[i].amount) - parseInt(arr[i].amountSurplus);
          }
          t.prizelist = arr;
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    //点击改变页数和数据
    edit(pid, index) {
      let t = this;
      t.i = index;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios({
        url: 'prize/getPrize',
        params: {
          prizeId: pid
        },
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          loading.close();
          t.update.id = pid;
          t.update.amount = res.data.data.amount;
          t.update.probability = res.data.data.probability;
          t.update.drawDayCount = res.data.data.drawDayCount;
          t.show = true;
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    save() {
      let t = this;
      if (t.update.amount === '' || t.update.probability === '' || t.update.drawDayCount === '') {
        t.$message.error('数值不能为空');
      } else if (parseInt(t.update.probability) > 100) {
        t.$message.error('中奖概率不能超过100');
      } else if (parseInt(t.update.amount) < parseInt(t.prizelist[t.i].amountSurplus)) {
        t.$message.error('总数不能小于累计数量');
      } else if (parseInt(t.update.drawDayCount) > parseInt(t.update.amount)) {
        t.$message.error('每日上限不能大于总数');
      } else if (parseInt(t.update.probability) < 0 || parseInt(t.update.drawDayCount) < 0 || parseInt(t.update.amount) < 0) {
        t.$message.error('数值不能小于0');
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        t.$axios({
          headers: {
            'auth-token': t.$cookies.get('token')
          },
          method: 'put',
          url: 'prize/setPrize',
          data: t.update
        }).then(res => {
          if (res.data.code == 200) {
            loading.close();
            t.$message({
              message: '编辑成功',
              type: 'success'
            });
            t.show = false;
            t.init();
          } else {
            loading.close();
            t.$message.error(res.data.message || '请刷新重试');
          }
        }).catch(err => {
          loading.close();
          t.$message.error(err.message || '内部服务器错误请联系管理员');
        });
      }
    },
    save_show() {
      let t = this;
      t.show = false;
    }
  }
};