var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "prize"
  }, [_c('Nav'), _c('Header'), _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "screen"
  }, [_c('div', {
    staticClass: "inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入中奖用户名称",
      "type": "text"
    },
    model: {
      value: _vm.userName,
      callback: function ($$v) {
        _vm.userName = $$v;
      },
      expression: "userName"
    }
  })], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入奖品名称",
      "type": "text"
    },
    model: {
      value: _vm.prizeName,
      callback: function ($$v) {
        _vm.prizeName = $$v;
      },
      expression: "prizeName"
    }
  })], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入手机号",
      "type": "text",
      "maxlength": "11"
    },
    on: {
      "input": function ($event) {
        _vm.phoneNo = _vm.phoneNo.replace(/[^\d]/g, '');
      }
    },
    model: {
      value: _vm.phoneNo,
      callback: function ($$v) {
        _vm.phoneNo = $$v;
      },
      expression: "phoneNo"
    }
  })], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-select', {
    attrs: {
      "clearable": "",
      "placeholder": "请选择订单状态"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "未核销",
      "value": "0"
    }
  }), _c('el-option', {
    attrs: {
      "label": "已核销",
      "value": "1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-date-picker', {
    attrs: {
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      "type": "date",
      "placeholder": "选择中奖日期"
    },
    model: {
      value: _vm.drawTime,
      callback: function ($$v) {
        _vm.drawTime = $$v;
      },
      expression: "drawTime"
    }
  })], 1), _c('div', {
    staticClass: "inp"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("查询")]), _vm.excel_status == 1 ? _c('el-button', [_c('download-excel', {
    staticClass: "export-btn",
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "data": _vm.derive_list,
      "fields": _vm.jsonFields,
      "type": "xls",
      "header": "兑奖列表",
      "name": "兑奖列表.xls"
    }
  }, [_vm._v(" 导出 ")])], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "tab"
  }, [_c('el-table', {
    staticClass: "table",
    staticStyle: {
      "width": "95%",
      "margin": "auto"
    },
    attrs: {
      "header-cell-style": {
        background: '#F4F6FA'
      },
      "data": _vm.prizelist
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "prizeName",
      "label": "奖品名称"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "receiveTime",
      "width": "160",
      "label": "中奖时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "userName",
      "label": "中奖人"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "phoneNo",
      "label": "中奖人手机号"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row: {
          receiveType
        }
      }) {
        return [receiveType == 0 ? _c('div', {
          staticStyle: {
            "color": "#EB8B49"
          }
        }, [_vm._v("未核销")]) : _vm._e(), receiveType == 1 ? _c('div', {
          staticStyle: {
            "color": "#82BD8E"
          }
        }, [_vm._v("已核销")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "storeName",
      "label": "核销店铺"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "writeOffTime",
      "width": "160",
      "label": "核销时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "expressNumber",
      "label": "快递单号"
    }
  }), _c('el-table-column', {
    attrs: {
      "type": "index",
      "width": "160",
      "align": "center",
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.prizeName == '8.88元现金红包' && scope.row.receiveType == 0 ? _c('el-button', {
          attrs: {
            "type": "primary",
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.dis(scope.row.id);
            }
          }
        }, [_vm._v(" 核销 ")]) : _vm._e(), scope.row.prizeName != '8.88元现金红包' || scope.row.receiveType != 0 ? _c('div', [_vm._v("暂无操作")]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "pag"
  }, [_c('el-pagination', {
    attrs: {
      "layout": "  prev, pager, next, sizes, jumper",
      "page-sizes": [10],
      "page-size": _vm.pagesize,
      "total": _vm.total,
      "current-page": _vm.currentPage
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]), _c('el-dialog', {
    attrs: {
      "lock-scroll": false,
      "title": "核销",
      "visible": _vm.show
    },
    on: {
      "update:visible": function ($event) {
        _vm.show = $event;
      }
    }
  }, [_c('div', {
    staticClass: "from"
  }, [_c('div', {
    staticClass: "from-inp"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入内容",
      "type": "text"
    },
    model: {
      value: _vm.redpacketCode,
      callback: function ($$v) {
        _vm.redpacketCode = $$v;
      },
      expression: "redpacketCode"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("红包码")])], 2)], 1), _c('div', {
    staticClass: "from-inp"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.deliver
    }
  }, [_vm._v("确认")]), _c('el-button', {
    attrs: {
      "type": "warning",
      "plain": ""
    },
    on: {
      "click": _vm.prize_show
    }
  }, [_vm._v("取消")])], 1)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_vm._v(" 兑奖管理 ")])]);
}];
export { render, staticRenderFns };