var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tac"
  }, [_c('el-menu', {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "router": "",
      "default-active": _vm.nav,
      "background-color": "#324157",
      "text-color": "#fff",
      "active-text-color": "#5586BE"
    }
  }, _vm._l(_vm.menu, function (val) {
    return _c('el-menu-item', {
      key: val.id,
      attrs: {
        "index": val.path
      }
    }, [_c('i', {
      class: val.icon
    }), _c('span', {
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(val.name))])]);
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };