import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    Login() {
      let t = this;
      if (t.username.length < 1) {
        t.$message.error('请输入正确的用户名');
      } else if (t.password.length < 6) {
        t.$message.error('密码长度不得小于6');
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        t.$axios.post('auth/getToken', {
          username: t.username,
          password: t.password
        }).then(function (res) {
          if (res.data.code == 200) {
            loading.close();
            t.$cookies.set("token", res.data.data, {
              expires: "3D"
            });
            t.$cookies.set("user", t.username, {
              expires: "3D"
            });
            t.$message({
              message: '登录成功',
              type: 'success'
            });
            t.$router.push({
              path: '/'
            });
          } else {
            loading.close();
            t.$message.error('账号密码错误');
          }
        }).catch(function (error) {
          loading.close();
          t.$message.error(error.message || '内部服务器错误请联系管理员');
        });
      }
    }
  }
};