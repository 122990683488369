import "core-js/modules/es.array.push.js";
import Header from '../components/Header';
import Nav from '../components/nav';
export default {
  name: 'Home',
  components: {
    Header,
    Nav
  },
  data() {
    return {
      config: 0,
      //系统配置
      edit_config: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios({
        url: 'config/getConfigList',
        params: {},
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          t.config = res.data.data;
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    save() {
      let t = this;
      for (let i = 0; i < t.config.length; i++) {
        t.edit_config.push({
          key: t.config[i].sysKey,
          value: t.config[i].sysValue
        });
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios.post('config/setConfigList', t.edit_config, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          t.$message({
            message: res.data.msg || '修改成功',
            type: 'success'
          });
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
        t.init();
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    }
  }
};