import "core-js/modules/es.array.push.js";
import Header from '../components/Header';
import Nav from '../components/nav';
export default {
  name: 'Home',
  components: {
    Header,
    Nav
  },
  data() {
    return {
      prizelist: [],
      //奖品列表
      currentPage: 1,
      //展示第几页
      pagesize: 10,
      //每页的数据条数
      total: 0,
      //总页数
      userName: '',
      //筛选条件用户名称
      prizeName: '',
      //筛选条件奖品名称
      drawTime: '',
      //筛选条件时间
      phoneNo: '',
      //筛选条件手机号
      type: '',
      //筛选条件订单类型
      show: false,
      //弹出层显示隐藏变量
      redpacketCode: '',
      //发货填写快递单号变量
      id: '',
      //选中发货对应行id
      derive_list: [],
      //导出数据,
      jsonFields: {
        //表格头
        '奖品名称': 'prizeName',
        '中奖时间': 'receiveTime',
        '中奖人': 'userName',
        '中奖人手机号': 'phoneNo',
        '状态': 'receiveType',
        '核销店铺': 'storeName',
        '核销时间': 'writeOffTime',
        '快递单号': 'expressNumber'
      },
      excel_status: 0,
      //导出全部数据是否加载完毕
      pages: 0 //总页数
    };
  },
  created() {
    //生命周期钩子创建后
    let t = this;
    t.init(); //调用初始化数据
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init(); //改变页码之后调用初始化数据
    },
    //点击改变页数和数据

    educe() {
      let t = this;
      t.derive_list = [];
      t.excel_status = 0;
      t.$axios.post('prize/getPrizeListExport', {
        userName: t.userName,
        prizeName: t.prizeName,
        drawTime: t.drawTime,
        phoneNo: t.phoneNo,
        receiveType: t.type
      }, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          let arr = res.data.data;
          for (let ind = 0; ind < arr.length; ind++) {
            if (arr[ind].receiveType == 0) {
              arr[ind].receiveType = '未核销';
            } else {
              arr[ind].receiveType = '已核销';
            }
            t.derive_list.push(arr[ind]);
          }
          t.excel_status = 1;
        }
      });
    },
    init() {
      //初始化数据获取奖品列表
      let t = this;
      t.prizelist = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios.post('prize/getPrizeListDynamic', {
        userName: t.userName,
        prizeName: t.prizeName,
        drawTime: t.drawTime,
        phoneNo: t.phoneNo,
        current: t.currentPage,
        receiveType: t.type
      }, {
        headers: {
          'auth-token': t.$cookies.get('token')
        }
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          t.prizelist = res.data.data.records;
          t.pagesize = parseInt(res.data.data.size);
          t.total = parseInt(res.data.data.total);
          t.pages = parseInt(res.data.data.pages);
          t.educe();
        } else {
          loading.close();
          t.$message.error(res.data.message || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    dis(id) {
      //显示弹出层
      let t = this;
      t.show = true;
      t.id = id;
    },
    deliver() {
      //确认发货
      let t = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      t.$axios({
        headers: {
          'auth-token': t.$cookies.get('token')
        },
        method: 'put',
        url: 'prize/writeOffRedpacket',
        data: {
          prizeListId: t.id,
          redpacketCode: t.redpacketCode
        }
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          t.$message({
            message: '操作成功',
            type: 'success'
          });
          t.init();
          t.show = false;
          t.redpacketCode = '';
        } else {
          loading.close();
          t.$message.error(res.data.msg || '请刷新重试');
        }
      }).catch(err => {
        loading.close();
        t.$message.error(err.message || '内部服务器错误请联系管理员');
      });
    },
    prize_show() {
      //关闭弹出层
      let t = this;
      t.show = false;
      t.redpacketCode = '';
    }
  }
};