var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "config"
  }, [_c('Nav'), _c('Header'), _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "tab"
  }, [_vm._l(_vm.config, function (value) {
    return _c('div', {
      key: value.id,
      staticClass: "form"
    }, [_c('el-input', {
      attrs: {
        "placeholder": "请输入内容",
        "type": "text"
      },
      on: {
        "input": function ($event) {
          value.sysValue = value.sysValue.replace(/[^\d]/g, '');
        }
      },
      model: {
        value: value.sysValue,
        callback: function ($$v) {
          _vm.$set(value, "sysValue", $$v);
        },
        expression: "value.sysValue"
      }
    }, [_c('template', {
      slot: "prepend"
    }, [_vm._v(_vm._s(value.remark) + "：")])], 2)], 1);
  }), _vm.config ? _c('div', {
    staticClass: "form"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("保存")])], 1) : _vm._e()], 2)])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_vm._v(" 配置管理 ")])]);
}];
export { render, staticRenderFns };